
import Http from './Http'

export default {
    async getMatches() {
        return await Http.get(`/football/matches`)
    },

}



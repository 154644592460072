
import Http from './Http'

export default {
    async getSeasonSchedule() {
        return await Http.get(`/ticketing/seasonSchedule`)
    },
    async getSubscriptionMasterData(data = '') {
        return await Http.get(`/ticketing/subscriptions?${data}`)
    },
    async getSubscriptionPricingData(data = '') {
        return await Http.get(`/ticketing/subscriptions/pricing?${data}`)
    },
    async getSubscriptionAreaData(data = '') {
        return await Http.get(`/ticketing/subscriptions/area?${data}`)
    },
    async getSubscriptionValidCards(data = '') {
        return await Http.get(`/ticketing/subscriptions/validcards?${data}`)
    },
    async getSubscriptionUnassignedCards(data = '') {
        return await Http.get(`/ticketing/subscriptions/unassignedcards?${data}`)
    },
    async getSubscriptionOtherCards(data = '') {
        return await Http.get(`/ticketing/subscriptions/othercards?${data}`)
    },
    async getSeasonTicketTags(subscription_number) {
        return await Http.get(`/ticketing/subscriptions/tags/${subscription_number}`)
    },
    async getSubscriptionTagsTypes(data) {
        return await Http.get(`/ticketing/subscriptions/tagsTypes`, data)
    },
    async updateSubscriptionTags(data) {
        return await Http.post(`/ticketing/subscriptions/tags`, data)
    },
    async deleteSubscriptionTags(data) {
        return await Http.delete(`/ticketing/subscriptions/tags?${data}`)
    },
    async getUserByCrmId(customer_id) {
        return await Http.get(`/ticketing/users/info/${customer_id}`)
    },
    async getCustomerSeasonTickets(data) {
        return await Http.get(`/ticketing/seasonTickets?${data}`)
    },
    async getCustomerMatchTickets(data) {
        return await Http.get(`/ticketing/matchTickets?${data}`)
    },

    async attachTicketingUserToCustomer(data) {
        return await Http.patch(`/ticketing/users/attach`, data)
    },
    async detachTicketingUserFromCustomer(data) {
        return await Http.patch(`/ticketing/users/detach`, data)
    },
    async getMatchesTickets(data) {
        return await Http.get(`/ticketing/matchesTickets?${data}`)
    },
    async updateSalePeriod(remote_match_id,data){
        return await Http.patch(`/ticketing/updateSalePeriod/remote_match_id`,data)
    },
    async deassociateSeasonTicket(data) {
        return await Http.patch(`/ticketing/seasonTickets/deassociate`, data)
    },
    async getAxessEvents(data) {
       return await Http.get(`/ticketing/axess/events?${data}`)
    },
    async getAxessEventCronLog(data) {
       return await Http.get(`/ticketing/axess/events/log?${data}`)
    },
    async getAxessScannerData(data) {
       return await Http.get(`/ticketing/axess/scannerData?${data}`)
    },
    async getAxessStatistics(data) {
        return await Http.get(`/ticketing/axess/statistics?${data}`)
    },
    async setupAxessEvent(data) {
        return await Http.post(`/ticketing/axess/event_setup`, data)
    },
    async sendToAxess(data) {
        return await Http.post(`/ticketing/axess/send`, data)
    },
    async getSeasonProducts(data){
        return await Http.get(`/ticketing/SeasonProducts?${data}`)
    },
    async updateSeasonProduct(data){
        return await Http.patch(`/ticketing/updateSeasonProduct`,data)
    },
    async getMatchTickets(data = '') {
        return await Http.get(`/ticketing/singleMatchTickets?${data}`)
    },

    async getMatchTicketsPricingData(data = '') {
        return await Http.get(`/ticketing/singleMatchTickets/pricing?${data}`)
    },

    async getMatchTicketsAreaData(data = '') {
        return await Http.get(`/ticketing/singleMatchTickets/area?${data}`)
    },
    async getSeasonSetup(data){
        return await Http.get(`/ticketing/seasonSetup?${data}`)
    },
    async updateSeasonSetup(data){
        return await Http.patch(`/ticketing/seasonSetup`,data)
    },
    async addSeasonSetup(data){
        return await Http.post(`/ticketing/seasonSetup`,data)
    },

}


